import {
  NavbarWrapper,
  LogoWrapper,
  MenuButton,
  CloseButton,
  ButtonWrapper,
  LinkWrapper,
  StyledLink,
} from "./NavbarHeader.styles";
import { NavItem } from "../../../../styles/Text.styles";
import { BasicButton } from "../../BasicButton/BasicButton.component";
import Link from "next/link";

export const NavbarHeader = ({
  hamburgerVersion = false,
  light = false,
  footerSwitch = false,
  menuOpen,
  setMenuOpen,
  buttonText,
  buttonUrl,
  buttonUrlType,
  buttonSubject,
  logo,
  linkList,
  router,
}) => {
  return (
    <NavbarWrapper menuOpen={menuOpen} hamburgerVersion={hamburgerVersion} light={footerSwitch}>
      <Link href={linkList[0].url} prefetch={false}>
        <LogoWrapper menuOpen={menuOpen} hamburgerVersion={hamburgerVersion} light={light || footerSwitch}>
          <img src={process.env.REACT_APP_IMG_MEDIA_SRC + logo.url} />
        </LogoWrapper>
      </Link>
      <LinkWrapper>
        {linkList.map((item, index) => (
          <StyledLink
            key={index}
            className={router.asPath === item.url ? "active" : ""}
            light={light || footerSwitch}
          >
            <NavItem href={item.url} prefetch={false}>{item.label}</NavItem>
          </StyledLink>
        ))}
      </LinkWrapper>
      <ButtonWrapper light={light || footerSwitch}
      >
        <BasicButton
          secondary
          href={buttonUrl}
          label={buttonText}
          urlType={buttonUrlType}
          mailSubject={buttonSubject}
        />
      </ButtonWrapper>
      {menuOpen ? (
        <CloseButton onClick={() => setMenuOpen(false)} menuOpen={menuOpen} />
      ) : (
        <MenuButton light={light || footerSwitch} onClick={() => setMenuOpen(true)} menuOpen={menuOpen} />
      )}
    </NavbarWrapper>
  );
};
