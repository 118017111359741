import Link from "next/link";
import { removeParagraphs } from "../../../helpers";
import {
  MainWrapper,
  Message,
  MessageText,
  OpenPositionsButton,
} from "./JoinUs.styles";

export const JoinUs = ({ text, buttonText, buttonUrl }) => {
  return (
    <MainWrapper>
      <Message>
        <MessageText>
          {removeParagraphs(text)}
        </MessageText>
        <Link href={buttonUrl} prefetch={false}>
          <OpenPositionsButton>
            <b>{removeParagraphs(buttonText)}</b>
          </OpenPositionsButton>
        </Link>
      </Message>
    </MainWrapper>
  );
};
