import styled from "styled-components";
import { MainGridWrapper } from "../../../styles/Main.styles";
import { colors } from "../../../styles/colors";
import { deviceSizes } from "../../../styles/deviceSizes";

export const MainWrapper = styled(MainGridWrapper)`
  width: 100%;
  background: ${colors.anthrazit};
  margin-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-bottom: 20px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 30px;
  }
`;

export const Message = styled.div`
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  margin: 9px 0px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 3/11;
    flex-direction: row;
    gap: 20px;
    margin: 17px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    gap: 40px;
    margin: 22px 0px;
  }
`;

export const MessageText = styled.div`
  font-family: "Neue Haas Grotesk Display Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  line-height: 16px;
  letter-spacing: 0.05em;
  justify-content: center;
  color: ${colors.weis};
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: normal;
  }
`;

export const OpenPositionsButton = styled(MessageText)`
  cursor: pointer;
`;
