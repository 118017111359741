import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import MenuIcon from "../../../../public/menuIcon.svg";
import CloseIcon from "../../../../public/closeIcon.svg";
import { deviceSizes } from "../../../../styles/deviceSizes";
export const NavbarWrapper = styled(MainGridWrapper)`
  background-color: ${colors.transparent};
  mask-image: linear-gradient(180deg, #ffffff 85%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(4px);
  grid-column: 1/3;
  position: sticky;
  grid-template-rows: auto;
  align-items: center;
  mix-blend-mode: ${({ light }) => (light ? 'difference' : 'none')};
  top: 0px;
  width: 100%;
  z-index: 4;
  padding: ${({ hamburgerVersion }) => (hamburgerVersion ? "0" : "")};
  height: 85px;
  margin-top: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    height: 99px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-self: end;
  grid-column: 10/13;
  width: fit-content;
  display: none;
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    filter: ${({ light }) => light && `invert(1)`};
    display: inherit;
    &:hover {
    }
  }
`;

export const LogoWrapper = styled.div`
  margin: 10px 0px;
  grid-column: 1/2;
  transform: translateX(-6px);
  cursor: pointer;
  & > img {
    height: 65px;
    filter: ${({ hamburgerVersion, light }) => ((hamburgerVersion || light) ? 'invert(1)' : 'none')};
    visibility: visible;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/4;
    & > img {
      height: 78px;
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/3;
  }
`;
export const MenuButton = styled(MenuIcon)`
  justify-self: end;
  margin-top: 20px;
  align-self: start;
  grid-column: 2/3;
  cursor: pointer;
  color: ${({ light }) => (light ? colors.weis : colors.anthrazit)};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 12/13;
    margin-top: 25px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    display: none;
    margin-top: 20px;
  }
`;

export const CloseButton = styled(CloseIcon)`
  justify-self: end;
  align-self: start;
  grid-column: 2/3;
  cursor: pointer;
  height: 30px;
  margin-top: 20px;
  color: ${colors.weis};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 12/13;
    height: 40px;
    margin-top: 15px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    display: none;
  }
`;

export const LinkWrapper = styled.div`
  gap: 32px;
  justify-content: flex-end;
  grid-column: 3/9;
  display: none;
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    display: flex;
  }
`;

export const StyledLink = styled.div`
  justify-self: start;
  color: ${({ light }) => (light ? colors.weis : colors.anthrazit)};
  cursor: pointer;
  &.active{  
    border-bottom:${({ light }) => (light ? `1px solid ${colors.weis}` : `1px solid ${colors.anthrazit}`)};
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    display: flex;
    &:hover {
      border-bottom: ${({ light }) => (light ? `1px solid ${colors.weis}` : `1px solid ${colors.anthrazit}`)};
    }
  }
`;
