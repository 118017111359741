import {
  HamburgerMenuWrapper,
  ItemsWrapper,
  HamburgerLink,
  Email,
  EmailWrapper,
} from "./Navbar.styles";
import { JoinUs } from "../JoinUs/JoinUs.component";
import { useEffect, useState } from "react";
import { MobileNavItem } from "../../../styles/Text.styles";
import { useRouter } from "next/router";
import Link from "next/link";
import { NavbarHeader } from "./NavbarHeader/NavbarHeader.component";
import { removeParagraphs } from "../../../helpers";

export const Navbar = ({ data, navbarSwitch, light = false }) => {
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", () => {
      setScrollY(window.scrollY);
    });
  }
  useEffect(() => {
    if (menuOpen) {
    } else {
      window.scrollTo(0, parseInt(scrollY || "0"));
    }
  }, [menuOpen]);
  return (
    <>
      <JoinUs menuOpen={menuOpen} text={removeParagraphs(data.message)} buttonText={removeParagraphs(data.linktext)} buttonUrl={data.linkurl} />
      <NavbarHeader
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        linkList={data.linksflex}
        buttonText={removeParagraphs(data.button.label)}
        footerSwitch={navbarSwitch}
        buttonUrl={data.button.url}
        buttonUrlType={data.button.linktype}
        buttonSubject={data.button.topic}
        router={router}
        logo={data.logo}
        light={light}
      />
      <HamburgerMenuWrapper menuOpen={menuOpen}>
        <NavbarHeader
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          linkList={data.linksflex}
          router={router}
          buttonText={removeParagraphs(data.button.label)}
          buttonUrl={data.button.url}
          buttonUrlType={data.button.linktype}
          buttonSubject={data.button.topic}
          logo={data.logo}
          hamburgerVersion={"true"}
        />
        <ItemsWrapper>
          {data.linksflex.map((item, index) => (
            <HamburgerLink
              key={index}
              className={router.asPath === item.url ? "active" : ""}
            >
              <MobileNavItem href={item.url} onClick={() => setMenuOpen(false)} prefetch={false}>
                {item.label}
              </MobileNavItem>
            </HamburgerLink>
          ))}
        </ItemsWrapper>
        <EmailWrapper>
          <Link prefetch={false} href={`mailto:${data.mobileemail.url ? `${data.mobileemail.url}${data.mobileemail.topic !== "false" ? `?subject=${data.mobileemail.topic}` : ''}` : 'info@thalia-drs.de'}`}>
            <Email>{data.mobileemail.text}</Email>
          </Link>
        </EmailWrapper>
      </HamburgerMenuWrapper>
    </>
  );
};
