import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { MainGridWrapper } from "../../../styles/Main.styles";
import { deviceSizes } from "../../../styles/deviceSizes";
import { ContinuousText } from "../../../styles/Text.styles";

export const HamburgerLink = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.weis};
  cursor: pointer;
  & > a {
    border-bottom: 1px solid ${colors.transparent};
  }
  &.active {
    & > a {
      border-bottom: 1px solid ${colors.weis};
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    &:hover {
      & > a {
        border-bottom: 1px solid ${colors.weis};
      }
    }
  }
`;

export const HamburgerMenuWrapper = styled(MainGridWrapper)`
  background: ${colors.anthrazit};
  position: fixed;
  grid-template-rows: auto auto 1fr;
  top: ${({ menuOpen }) => (menuOpen ? "0px" : "100%")};
  visibility: ${({ menuOpen }) => (menuOpen ? "visible" : "hidden")};
  transition: ${({ menuOpen }) =>
    menuOpen ? "0.45s ease-in-out" : "0.15s ease-in-out"};
  width: 100%;
  z-index: 4;
  height: 100%;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    background: ${colors.weis};
    display:none;
    top: ${({ menuOpen }) => (menuOpen ? "74px" : "100%")};
    height: ${({ menuOpen }) => (menuOpen ? "calc(100vh - 74px);" : "0px")};
  }
`;

export const ItemsWrapper = styled.div`
  grid-column: 1/3;
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  margin-top: 80px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    margin-top: 120px;
    gap: 60px;
  }
`;

export const EmailWrapper = styled.div`
  grid-column: 1/3;
  display: flex;
  align-items: end;
  justify-self: center;
  height: 100%;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
  }
`;

export const Email = styled(ContinuousText)`
  color: ${colors.weis};
  margin-bottom: 20px;
  cursor: pointer;
`;
